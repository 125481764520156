<template>
    <div class="gameChat">
        <div class="gameChat-entry" v-show="!chatStatus" @click="()=>{
            $store.state.game.game_state != 'game-closed' && $store.state.game.game_state != 'force-close-pre-connection' ?
            (chatStatus=!chatStatus,$store.commit('RELATED_NEWS_COUNTS',true))
            : ($store.commit('MESSAGE_TEXT', '云游戏已关闭，无法加入聊天室'),$store.commit('MESSAGE_TYPE', false))
        }">
            <i></i>
            <span>进入聊天</span>
            <sup v-if="$store.state.chat.related_news_counts!=0">{{$store.state.chat.related_news_counts}}</sup>
            <i></i>
        </div>
        <div class="gameChat-con" v-show="chatStatus">
            <div class="gameChat-header">
                <i></i>
                <span>大厅</span>
                <img @click="()=>{nsConn.conn.close()}" src="../../assets/images/game/gameChat-x.png" title="关闭聊天室">
                <img @click="()=>{chatStatus=false}" src="../../assets/images/game/gameChat-min.png" title="最小化聊天室">
            </div>
            <ul class="gameChat-aside">
                <li v-for="item in roomList" :class="[item.room_info.room_id == nowRoomId ? 'li-active' : '']" :title="item.room_info.room_name" @click="selectRoom(item)">
                    {{item.room_info.room_name}}
                    <span v-if="false"></span>
                    <i v-if="false"></i>
                </li>
            </ul>
            <div class="gameChat-airing" v-if="$store.state.chat.join_status">
                <i></i>
                <p>绿色交流，违规者将被关进小黑屋哦！<span>最新消息</span>：暂无活动参加！</p>
            </div>
            <div class="gameChat-box" v-if="$store.state.chat.join_status">
                <template v-if="chatList">
                    <p :class="item.status ? 'gameChat-boxItemExtra' : item.at_user_uuids.filter((i)=>{ return i == $store.state.login.user.uuid}).length != 0
                    ? 'gameChat-atItem' : 'gameChat-boxItem'" v-for="item in chatList" :key="item.msg_id" @contextmenu="ClickChatInfo($event, item)">
                        <span :class="item.sender.user_uuid != $store.state.login.user.uuid ? 'own' : ''">{{item.sender.user_name}} :</span>{{item.content}}
                        <i v-if="item.status" :class="[item.status == 1 ? 'sending' : '']" @click="reSendMessage(item)"></i>
                    </p>
                </template>
            </div>
            <div class="gameChat-failBox" v-else>
                <img src="../../assets/images/game/gameChat-connectfail.png" alt="">
                <span>加入聊天失败</span>
                <p>此故障可能表明您的本地网络处于脱机状态，或者服务器当前处于脱机状态。</p>
                <div @click="reconnect()">重新加入</div>
            </div>
            <div class="gameChat-roomIdInput">
                <el-input v-model="roomId" placeholder="请输入房间名"></el-input>
                <img src="../../assets/images/game/gameChat-enterroom.png" title="进入房间" @click="enterRoom()">
            </div>
            <div class="gameChat-createRoom">
                <img src="../../assets/images/game/gameChat-createroom.png" title="创建房间" @click="createRoom()">
            </div>
            <div class="gameChat-list">
                <div class="gameChat-listHeader">
                    <i></i>
                    <span>成员</span>
                    <span>{{peopleCount}}</span>
                    <img @click="banAllVoice()" v-if="(userOwnInfo.identity=='manager' || userOwnInfo.identity=='owner' || userOwnInfo.identity=='sysAdmin') && !isBanAudio" src="../../assets/images/game/gameChat-peoplenovoice.png" title="一键禁麦">
                    <img @click="banAllVoice()" v-if="(userOwnInfo.identity=='manager' || userOwnInfo.identity=='owner' || userOwnInfo.identity=='sysAdmin') && isBanAudio" src="../../assets/images/game/gameChat-banvoice.png" title="解除禁麦">
                    <img @click="banAllText()" v-if="(userOwnInfo.identity=='manager' || userOwnInfo.identity=='owner' || userOwnInfo.identity=='sysAdmin') && !isBanText" src="../../assets/images/game/gameChat-text.png" title="一键禁言">
                    <img @click="banAllText()" v-if="(userOwnInfo.identity=='manager' || userOwnInfo.identity=='owner' || userOwnInfo.identity=='sysAdmin') && isBanText" src="../../assets/images/game/gameChat-bantext.png" title="解除禁言">
                </div>
                <div class="gameChat-listInput">
                    <el-input v-model="userName" placeholder="请输入用户名" @input="searchUser()"></el-input>
                    <img src="../../assets/images/game/gameChat-search.png">
                </div>
                <template v-if="usersList">
                    <div class="gameChat-listItem" v-for="(item,name) in  JSON.stringify(usersShowList) == '{}' ? usersList : usersShowList" :key="name" @contextmenu="rightClickInfo($event, item)">
                        <img :src="item.user_avatar" alt="">
                        <span v-if="item.identity != 'audience'" :class="[item.identity == 'owner' ? 'ownerIcon' : 'adminIcon']"></span>
                        <p>{{item.user_name}}</p>
                        <i v-if="item.auth.audio_auth && false" @click="isGetVoice(item)"
                           :class="((item.identity == 'audience' && isBanAudio) || (item.auth.ban_audio_expire_time - Date.now()/1000 > 0)) ?
                           'banMike' :
                           item.mikeStatus ?
                           'shieldMike' :
                           audioArray.indexOf(item.user_uuid)!=-1 ?
                           'mikeIcon' :
                           'noMike'"
                           :title="item.mikeStatus ? '开麦' : '闭麦'"></i>
                        <i v-if="(item.auth.text_auth && item.auth.ban_text_expire_time - Date.now()/1000 > 0) || (isBanText && item.identity == 'audience')" class="ban-text"></i>
                        <i v-if="item.user_uuid != $store.state.login.user.uuid && (userOwnInfo.identity=='manager' || userOwnInfo.identity=='owner' || userOwnInfo.identity=='sysAdmin')" class="kickOut" @click="()=>{$store.commit('MESSAGE_TEXT', '敬请期待')}"></i>
                    </div>
                </template>
            </div>
            <div class="gameChat-settingGroup">
                <div class="gameChat-em" @click="()=>{this.isText ? emojiStatus=!emojiStatus : ''}">
                    <div class="gameChat-emoji" v-show="emojiStatus" @click.stop="()=>{}">
                        <ul >
                            <li v-for="( itemC, indexC ) in commentEmojiPage" v-show="indexC == commentEmojiActive" :key="indexC">
                                <div v-for="(itemP, indexP) in itemC" :key="indexP" @click="commentInputEmoji(itemP)">
                                    {{ itemP }}
                                </div>
                            </li>
                        </ul>
                        <div class="emojiNav_btn">
                            <a v-for="(itemC, indexC) in commentEmojiPage" :key="indexC" @click="commentEmojiActive = indexC">{{ indexC + 1 }}</a>
                        </div>
                    </div>
                </div>
                <div :class="voiceValue==0 ? 'gameChat-noVoice' : 'gameChat-voice'" @click="()=>{voiceStatus=!voiceStatus}" v-if="false">
                    <div class="gameChat-voiceSetting" v-show="voiceStatus" @click.stop="()=>{}">
                        <el-slider
                                v-model="voiceValue"
                                vertical
                                @input="changeVoice()"
                                height="100px">
                        </el-slider>
                    </div>
                </div>
            </div>
            <div class="gameChat-btnGroup">
                <div class="gameChat-input">
                    <el-input
                            ref="commentEle"
                            :disabled="isText ? false : true"
                            :placeholder="isText ? '这里输入聊天内容' : isBanText ? '管理员已开启全体禁言' : '你已被管理员禁言'"
                            maxlength="100"
                            @keyup.enter.native="sendMessage()"
                            v-model="chatText">
                    </el-input>
                </div>
                <div :class="isText ? 'gameChat-btn' : 'gameChat-noBtn'" @click="sendMessage()">发送</div>
            </div>
            <ul class="gameChat-contextChatMenu" v-if="contextChatMenuStatus" :style="{position: 'fixed', left:contextChatMenuStyle.top+15+'px', top:contextChatMenuStyle.left+15+'px'}">
                <li @click="privateChat()">@ 他</li>
                <li @click="copyLink()">复制</li>
                <li @click="recallMessage()" :class="(temporaryItem.sender.user_uuid == $store.state.login.user.uuid) || (userOwnInfo.identity == 'owner') || (userOwnInfo.identity != 'audience' && temporaryItem.sender.identity == 'audience') ? '' : 'norecall'">撤回</li>
                <li v-if="userOwnInfo.identity == 'audience'" @click="()=>{reportStatus=true;contextChatMenuStatus=false;contextInfoMenuStatus=false}">举报</li>
                <template v-else>
                    <li v-if="usersList[temporaryItem.sender.user_uuid].auth.ban_text_expire_time - Date.now()/1000 <= 0" @click="banSingleText()" :class="(temporaryItem.sender.user_uuid == $store.state.login.user.uuid) || (temporaryItem.sender.identity != 'audience') ? 'norecall' : ''">禁言</li>
                    <li v-else @click="banSingleText()" :class="(temporaryItem.sender.user_uuid == $store.state.login.user.uuid) || (temporaryItem.sender.identity != 'audience') ? 'norecall' : ''">解除禁言</li>
                </template>
            </ul>
            <div class="gameChat-contextInfoMenu" v-if="contextInfoMenuStatus" :style="{position: 'fixed', left:contextInfoMenuParam.top+15+'px', top:contextInfoMenuParam.left+15+'px'}">
                <section>
                    <img :src=contextInfoMenuParam.href alt="">
                    <h2>{{contextInfoMenuParam.name}}</h2>
                </section>
                <div>
                    <li v-if="userOwnInfo.identity == 'audience'" @click="()=>{reportStatus=true;contextInfoMenuStatus=false;contextChatMenuStatus=false}">举报</li>
                    <li @click="banSingleVoice()" v-if="userOwnInfo.identity != 'audience' && !((temporaryItem.identity == 'audience' && isBanAudio) || (temporaryItem.auth.ban_audio_expire_time - Date.now()/1000 > 0))" :class="temporaryItem.identity != 'audience' ? 'noban' : ''">禁麦</li>
                    <li @click="banSingleVoice()" v-if="userOwnInfo.identity != 'audience' && ((temporaryItem.identity == 'audience' && isBanAudio) || (temporaryItem.auth.ban_audio_expire_time - Date.now()/1000 > 0))" :class="temporaryItem.identity != 'audience' ? 'noban' : ''">解除禁麦</li>
                    <li @click="banSingleText()" v-if="userOwnInfo.identity != 'audience' && !(temporaryItem.auth.text_auth && temporaryItem.auth.ban_text_expire_time - Date.now()/1000 > 0) || (isBanText && temporaryItem.identity == 'audience')" :class="temporaryItem.identity != 'audience' ? 'noban' : ''">禁言</li>
                    <li @click="banSingleText()" v-if="userOwnInfo.identity != 'audience' && (temporaryItem.auth.text_auth && temporaryItem.auth.ban_text_expire_time - Date.now()/1000 > 0) || (isBanText && temporaryItem.identity == 'audience')" :class="temporaryItem.identity != 'audience' ? 'noban' : ''">解除禁言</li>
                    <li @click="privateChat()">@ 他</li>
                </div>
            </div>
            <div class="gameChat-reportMenu" v-if="reportStatus">
                <i @click="()=>{reportStatus=false}"></i>
                <div class="gameChat-reportRadio">
                    <template v-for="item in $store.state.chat.report_type">
                        <el-radio v-model="radio" :label="item.dict_code">{{item.dict_detail}}</el-radio>
                    </template>
                </div>
                <div class="gameChat-reportInput">
                    <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容(必填)"
                            v-model="reportText">
                    </el-input>
                </div>
                <div :class="reportText ? 'gameChat-reportBtn' : 'gameChat-noReportBtn'" @click="reportSubmit()">提交</div>
            </div>
        </div>
        <div style="display: none" id="local_stream"></div>
        <template v-for="i in RSId">
            <div style="display: none" :id="`remote_stream_${i}`"></div>
        </template>
    </div>
</template>

<script>
    import gameChatUtil from './index'
    import lcgWsUtil from '@/utils/lcgWsUtil'
    import TRTC from "trtc-js-sdk";

    export default {
        name: "GameChat",
        props: {
            wsServer:{
                type: String,
                default: ''
            }
        },
        data () {
            return {
                RSId: [],
                nsConn: null,
                chatText: '', // 输入文本
                audioClient: null, // 音视频客户端
                localStream: null, // 音视频本地流
                audioArray: [], // 远端说话人员
                voiceValue: 100,
                remoteStream: {},
                emojis: [
                    '😀','😃','😄','😁','😆','😅','🤣','😂','🙂','🙃','😉','😊','😇','🥰','😍','🤩','😘','😗','😚','😙',
                    '😋','😛','😜','🤪','😝','🤑','🤗','🤭','🤫','🤔','🤐','🤨','😐','😑','😶','😏','😒','🙄','😬','🤥',
                    '😌','😔','😪','🤤','😴','😷','🤒','🤕','🥵','🥶','🥴','😵','🥳','😎','🤓','🧐','😕','😟','🙁','😮',
                    '😯','😲','😳','🥺','😦','😧','😨','😰','😥','😢','😭','😱','😖','😣', '😞','😓','😩','😫','🥱','😤',
                    '😡','😠','😈','👿','‍🔥'
                ],
                emojiStatus: false, // 表情库展示控制
                commentEmojiPage: [],   //表情分页数据
                commentEmojiActive: 0,  //表情分页导航控制
                voiceStatus: false, // 声音调整控制
                chatStatus: false, // 聊天室展开控制
                chatNum: 0, // 用户是否退出语音聊天
                oldScrollHeight: '',
                contextChatMenuStyle: {},
                contextChatMenuStatus: false, // 鼠标右键聊天记录菜单显示状态
                contextInfoMenuParam: {},
                contextInfoMenuStatus: false, // 鼠标右键用户列表菜单显示状态
                roomId: '', // 输入的房间id
                nowRoomId: '', // 当前房间id
                userName: '', // 输入的用户名
                temporaryItem: null, // 右键时临时存储的用户信息
                isBanAudio: 0, // 是否开启一键禁麦
                isBanText: 0, // 是否开启一键禁言
                firstJoinRoom: false,
                allowAudio: false,
                timer: '', // 临时语音计时器
                timerText: '', // 临时文本计时器
                timerTwo: '', // 本地发消息反馈计时器
                timerThree: '', // 本地发消息次数计时器
                timerFour: '', // 消息提示计时器
                documentTitle: '',
                atUser: {},
                localStatus: true, // 是否有本地流
                radio: 6, // 举报分类
                reportText: '', // 举报说明文本
                reportStatus: false, // 举报弹窗状态
                peopleCount: 0, // 房间人数
                usersShowList: {},
                // prevDevices: null
            }
        },
        created() {
            this.documentTitle =  document.title
        },
        mounted() {
            // 表情分页数据处理
            this.commentEmojiDataHandle()
            // 点击其他地方隐藏设置栏
            this.hideModular()
            document.addEventListener('visibilitychange', this.onVisibilityChange)
            // 监听用户麦克风拔插行为
            // this.prevDevices = await TRTC.getDevices();
            // navigator.mediaDevices.addEventListener('devicechange', this.changeDevice)
        },
        beforeUpdate() {
            this.oldScrollHeight = document.querySelector('.gameChat-box').scrollHeight
        },
        updated() {
            let obj = document.querySelector('.gameChat-box')
            if (obj.scrollHeight != this.oldScrollHeight) {
                obj.scrollTop = obj.scrollHeight;
            }
        },
        computed: {
            isText() {
                if (this.userOwnInfo.identity != 'audience') {
                    return true
                } else {
                    return (this.userOwnInfo.auth.ban_text_expire_time - Date.now()/1000 <= 0 && this.userOwnInfo.auth.text_auth) && !this.isBanText
                }
            },
            isAudio() {
                if (this.userOwnInfo.identity != 'audience') {
                    return true
                } else {
                    return (this.userOwnInfo.auth.ban_audio_expire_time - Date.now()/1000 <= 0 && this.userOwnInfo.auth.audio_auth) && !this.isBanAudio
                }
            },
            usersList: {
                get() {
                    if (this.$store.state.chat.room_user_show_list[this.nowRoomId]) {
                        let arr = Object.keys(this.$store.state.chat.room_user_show_list[this.nowRoomId])
                        this.peopleCount = arr.length
                    }
                    return this.$store.state.chat.room_user_show_list[this.nowRoomId]
                }
            },
            roomList () {
                if (this.$store.state.chat.room_info_list[this.nowRoomId]) {
                    this.isBanAudio = this.$store.state.chat.room_info_list[this.nowRoomId].room_info.ban_audio
                    this.isBanText = this.$store.state.chat.room_info_list[this.nowRoomId].room_info.ban_text
                }
                return this.$store.state.chat.room_info_list
            },
            userOwnInfo() {
                if (this.$store.state.chat.room_info_list[this.nowRoomId]) {
                    return this.$store.state.chat.room_info_list[this.nowRoomId].user_info
                }
                return {user_info: {}}
            },
            chatList() {
                if (this.$store.state.chat.chat_record_list[this.nowRoomId]) {
                    return this.$store.state.chat.chat_record_list[this.nowRoomId]
                }
            },
        },
        watch: {
            // "$store.state.game.now_game_area"(state) {
                // if (state && this.$route.params.id == "9030") {
                //     let session = this.$store.state.game.session_id
                //     let wsServer = gameChatUtil.wsServer('',session)
                //     gameChatUtil.connectWsServer(wsServer, this.callback_onWsConnected, this.callback_onWsDisConnected);
                // }
            // },
            "$store.state.chat.room_info_list": {
                handler(state) {
                    this.nowRoomId = this.$store.state.chat.room_id
                    this.allowAudio =  state[this.nowRoomId].user_info.auth.ban_audio_expire_time - Date.now()/1000 <= 0 && state[this.nowRoomId].user_info.auth.audio_auth
                    this.isBanText = state[this.nowRoomId].room_info.ban_text
                    if (!this.firstJoinRoom) { // 初次进入
                        this.createChatRoom(0, state[this.nowRoomId])
                        this.$store.commit('UPDATE_USER_OWN', state[this.nowRoomId])
                        // if (this.userOwnInfo.identity != 'audience') {
                        //     this.createChatRoom(1, state[this.nowRoomId])
                        // } else {
                        //     if (state[this.nowRoomId].room_info.ban_audio) {
                        //         this.isBanAudio = 1
                        //         this.createChatRoom(0, state[this.nowRoomId])
                        //     } else {
                        //         this.isBanAudio = 0
                        //         this.allowAudio ? this.createChatRoom(1, state[this.nowRoomId]) : this.createChatRoom(0, state[this.nowRoomId])
                        //     }
                        // }
                    } else {
                        if (this.userOwnInfo.identity == 'audience') {
                            if (state[this.nowRoomId].room_info.ban_audio) {
                                this.isBanAudio = 1
                                this.allowAudio ? this.localStream.muteAudio() : ''
                            } else {
                                this.isBanAudio = 0
                                !this.allowAudio ? this.localStream.muteAudio() : this.localStream.unmuteAudio()
                            }
                        }
                        // let user_list = this.$store.state.chat.room_user_show_list
                        // this.$store.state.chat.room_user_show_list = Object.assign({}, this.$store.state.chat.room_user_show_list, user_list)
                    }
                },
                deep: true
            },
            chatStatus(state) {
                if (state) {
                    if (!this.chatNum) {
                        let session = this.$store.state.game.session_id
                        let wsServer = gameChatUtil.wsServer(this.wsServer, session)
                        gameChatUtil.connectWsServer(wsServer, this.callback_onWsConnected, this.callback_onWsDisConnected);
                        this.chatNum++
                    }
                }
            },
            "$store.state.game.game_state"(state) {
                if (state == 'game-closed' || state == 'force-close-pre-connection') {
                    this.quiteChatRoom(2)
                }
            },
            usersList: {
                handler(state) {
                    for (let i in state) {
                        state[i].mikeStatus ? (this.remoteStream[state[i].user_uuid] ? this.remoteStream[state[i].user_uuid].muteAudio() : '') : (this.remoteStream[state[i].user_uuid] ? this.remoteStream[state[i].user_uuid].unmuteAudio() : '')
                    }
                },
                deep: true
            },
            isText: {
                handler (state) {
                    if (!this.$store.state.chat.room_info_list[this.nowRoomId].room_info.ban_text && !state) {
                        let t = Math.ceil(this.userOwnInfo.auth.ban_text_expire_time - Date.now()/1000)
                        clearTimeout(this.timerText)
                        this.timerText = setTimeout(()=>{
                            clearTimeout(this.timerText)
                            let banAudioText = lcgWsUtil.wsChatBody(5000006)
                            this.nsConn.room(this.nowRoomId).emit("gameChat", banAudioText);
                        },t*1000)
                    }
                },
                deep: true
            },
            isAudio: {
                handler (state) {
                    if (!this.$store.state.chat.room_info_list[this.nowRoomId].room_info.ban_audio && !state) {
                        let t = Math.ceil(this.userOwnInfo.auth.ban_audio_expire_time - Date.now()/1000)
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            clearTimeout(this.timer)
                            let banAudioText = lcgWsUtil.wsChatBody(5000006)
                            this.nsConn.room(this.nowRoomId).emit("gameChat", banAudioText)
                        },t*1000)
                    }
                },
                deep: true
            },
            atUser: {
                handler(state) {
                    if (JSON.stringify(state) != '{}') {
                        let atList = ''
                        for (let i in state) {
                            atList = '@' + state[i] + ' '
                        }
                        this.chatText += atList
                    }
                },
                deep: true
            },
            chatText(state) {
                if (state) {
                    if (JSON.stringify(this.atUser) != '{}') {
                        for (let i in this.atUser) {
                            let text = '@' + this.atUser[i]
                            state.indexOf(text) == -1 ? delete this.atUser[i] : ''
                        }
                    }
                }
            },
            "$store.state.chat.related_news_counts"(state) {
                state != 0 ? this.onVisibilityChange() : ''
                this.chatStatus ? this.$store.commit('RELATED_NEWS_COUNTS',true) : ''
            }
        },
        methods: {
            enterRoom() {
                this.$store.commit('MESSAGE_TEXT', '敬请期待')
            },
            createRoom() {
                this.$store.commit('MESSAGE_TEXT', '敬请期待')
            },
            checkMessage(text, atUser, msgInfo) {
                let sender = {}
                let chat_own_record = {}
                let status = 1
                if (msgInfo) { // 重发
                    let messageText = lcgWsUtil.wsChatBody(5000011, text, '', '', atUser)
                    this.nsConn.room(this.nowRoomId).emit("gameChat", messageText)
                    chat_own_record.roomId = this.nowRoomId
                    chat_own_record.msgId = msgInfo.msg_id
                    chat_own_record[msgInfo.msg_id] = {
                        sender: msgInfo.sender,
                        content: text,
                        at_user_uuids: atUser,
                        msg_id: msgInfo.msg_id,
                        status: 1
                    }
                    this.$store.commit('CHAT_RECODE_LIST', chat_own_record)
                    this.timerTwo = setTimeout(() => {
                        clearTimeout(this.timerTwo)
                        status = this.$store.state.chat.chat_record_list[this.nowRoomId][msgInfo.msg_id].status ? this.$store.state.chat.chat_record_list[this.nowRoomId][msgInfo.msg_id].status : 0
                        if (status == 1) {
                            this.$store.state.chat.chat_record_list[this.nowRoomId][msgInfo.msg_id].status = 2
                        }
                    },10000)
                } else { // 非重发
                    let messageText = lcgWsUtil.wsChatBody(5000011, text, '', '', atUser)
                    this.nsConn.room(this.nowRoomId).emit("gameChat", messageText)
                    sender = {
                        user_name: this.userOwnInfo.user_name,
                        user_uuid: this.userOwnInfo.user_uuid,
                        user_avatar: this.userOwnInfo.user_avatar,
                        identity: this.userOwnInfo.identity
                    }
                    chat_own_record.roomId = this.nowRoomId
                    chat_own_record.msgId = 'msg_ownMessage'
                    chat_own_record.msg_ownMessage = {
                        sender: sender,
                        content: text,
                        at_user_uuids: atUser,
                        msg_id: 'msg_ownMessage',
                        status: 1
                    }
                    this.$store.commit('CHAT_RECODE_LIST', chat_own_record)
                    this.timerTwo = setTimeout(() => {
                        clearTimeout(this.timerTwo)
                        if (this.$store.state.chat.chat_record_list[this.nowRoomId].msg_ownMessage) {
                            status = this.$store.state.chat.chat_record_list[this.nowRoomId].msg_ownMessage.status
                            if (status == 1) {
                                let chat_list = this.$store.state.chat.chat_record_list
                                let id = "msg_" + Date.now()
                                chat_list[this.nowRoomId][id] = chat_list[this.nowRoomId].msg_ownMessage
                                chat_list[this.nowRoomId][id].status = 2
                                chat_list[this.nowRoomId][id].msg_id = id
                                delete chat_list[this.nowRoomId].msg_ownMessage
                                this.$store.state.chat.chat_record_list = Object.assign({}, this.$store.state.chat.chat_record_list, chat_list)
                            }
                        }
                    },10000)
                }
                this.chatText = ''
                this.atUser = []
            },
            reSendMessage(it) {
                this.$store.commit('FAIL_MSG_ID', it.msg_id)
                this.checkMessage(it.content,it.at_user_uuids,it)
            },
            searchUser() {
                let list = this.deepClone(this.$store.state.chat.room_user_show_list)
                for (let i in list[this.nowRoomId]) {
                    if (list[this.nowRoomId][i].user_name.indexOf(this.userName) == -1) {
                        delete list[this.nowRoomId][i]
                    }
                }
                this.usersShowList = list[this.nowRoomId]
            },
            recallMessage() {
                if(!((this.temporaryItem.sender.user_uuid == this.$store.state.login.user.uuid) || (this.userOwnInfo.identity == 'owner') || (this.userOwnInfo.identity != 'audience' && this.temporaryItem.sender.identity == 'audience'))) return
                let recallMessage = lcgWsUtil.wsChatBody(5000013, '', '', '','', '', '', this.temporaryItem.msg_id)
                this.nsConn.room(this.nowRoomId).emit("gameChat", recallMessage);
                this.contextChatMenuStatus = false
            },
            copyLink() {
                //创建input标签
                let input = document.createElement('input')
                //将input的值设置为需要复制的内容
                input.value = this.temporaryItem.content
                //添加input标签
                document.body.appendChild(input)
                //选中input标签
                input.select()
                //执行复制
                document.execCommand('copy')
                //成功提示信息
                this.$store.commit('MESSAGE_TYPE',true)
                this.$store.commit('MESSAGE_TEXT','复制成功')
                //移除input标签
                document.body.removeChild(input)
                this.contextChatMenuStatus = false
            },
            reportSubmit() {
                if (!this.reportText) return
                this.reportStatus = false
                let report_uuid = this.temporaryItem.user_uuid ? this.temporaryItem.user_uuid : this.temporaryItem.sender.user_uuid
                let msg_id = this.temporaryItem.msg_id ? this.temporaryItem.msg_id : 0
                let reportReason = lcgWsUtil.wsChatBody(5000051, this.reportText, '', '','', '', '', msg_id, this.radio, report_uuid)
                this.nsConn.room(this.nowRoomId).emit("gameChat", reportReason);
                this.$store.commit('MESSAGE_TYPE', true)
                this.$store.commit('MESSAGE_TEXT', '举报成功')
                this.reportText = ''
            },
            banSingleVoice() {
                let item = this.temporaryItem
                if (!this.isBanAudio) {
                    let banAudioText = ''
                    if ((item.auth.ban_audio_expire_time - Date.now()/1000 > 0)) {
                        banAudioText = lcgWsUtil.wsChatBody(5000005, '', '', '', [item.user_uuid], 'audio', 0)
                    } else {
                        banAudioText = lcgWsUtil.wsChatBody(5000005, '', '', '', [item.user_uuid], 'audio', 86400)
                    }
                    this.nsConn.room(this.nowRoomId).emit("gameChat", banAudioText);
                }
            },
            banAllVoice() {
                let banAudioText = lcgWsUtil.wsChatBody(5000003, '', '', '', '', 'audio')
                this.nsConn.room(this.nowRoomId).emit("gameChat", banAudioText);
            },
            banSingleText() {
                let item = this.temporaryItem
                if (!this.isBanText) {
                    let banAudioText = ''
                    if (item.sender) {
                        if ((item.sender.user_uuid == this.$store.state.login.user.uuid) || (item.sender.identity != 'audience')) return
                        if (this.usersList[this.temporaryItem.sender.user_uuid].auth.ban_text_expire_time - Date.now()/1000 > 0) {
                            banAudioText = lcgWsUtil.wsChatBody(5000005, '', '', '', [item.sender.user_uuid], 'text', 0)
                        } else {
                            banAudioText = lcgWsUtil.wsChatBody(5000005, '', '', '', [item.sender.user_uuid], 'text', 86400)
                        }
                        this.contextChatMenuStatus = false
                    } else {
                        if ((item.auth.ban_text_expire_time - Date.now()/1000 > 0)) {
                            banAudioText = lcgWsUtil.wsChatBody(5000005, '', '', '', [item.user_uuid], 'text', 0)
                        } else {
                            banAudioText = lcgWsUtil.wsChatBody(5000005, '', '', '', [item.user_uuid], 'text', 86400)
                        }
                    }
                    this.nsConn.room(this.nowRoomId).emit("gameChat", banAudioText)
                }
            },
            banAllText() {
                let banAudioText = lcgWsUtil.wsChatBody(5000003, '', '', '', '', 'text')
                this.nsConn.room(this.nowRoomId).emit("gameChat", banAudioText);
            },
            selectRoom(item) {
                this.nowRoomId = item.roomInfo.room_id
            },
            privateChat() {
                let user_name = this.temporaryItem.user_name ? this.temporaryItem.user_name : this.temporaryItem.sender.user_name
                let user_uuid = this.temporaryItem.user_uuid ? this.temporaryItem.user_uuid : this.temporaryItem.sender.user_uuid
                let atItem = {}
                atItem[user_uuid] = user_name
                this.atUser = Object.assign({}, this.atUser, atItem)
            },
            ClickChatInfo(e, item) {
                this.contextInfoMenuStatus = false
                this.contextChatMenuStatus = !this.contextChatMenuStatus
                if (this.contextChatMenuStatus) {
                    let h = document.documentElement.clientHeight || document.body.clientHeight;
                    if (e.clientY+170 > h) {
                        this.contextChatMenuStyle.top = e.clientX
                        this.contextChatMenuStyle.left = e.clientY - 170
                    } else {
                        this.contextChatMenuStyle.top = e.clientX
                        this.contextChatMenuStyle.left = e.clientY
                    }
                    this.temporaryItem = item
                }
                e.preventDefault();
            },
            rightClickInfo(e, item) {
                this.contextChatMenuStatus = false
                if (item.user_uuid == this.$store.state.login.user.uuid) return
                this.contextInfoMenuStatus = !this.contextInfoMenuStatus
                if (this.contextInfoMenuStatus) {
                    let h = document.documentElement.clientHeight || document.body.clientHeight;
                    if (e.clientY+100 > h) {
                        this.contextInfoMenuParam.top = e.clientX
                        this.contextInfoMenuParam.left = e.clientY - 100
                    } else {
                        this.contextInfoMenuParam.top = e.clientX
                        this.contextInfoMenuParam.left = e.clientY
                    }
                    this.contextInfoMenuParam.href = item.user_avatar
                    this.contextInfoMenuParam.name = item.user_name
                    this.temporaryItem = item
                }
                e.preventDefault();
            },
            reconnect() {
                let messageText = lcgWsUtil.wsChatBody(5000002)
                this.nsConn.emit("gameChat", messageText)
                this.$store.commit('JOIN_STATUS',true)
            },
            changeVoice() {
                let a = this.voiceValue/100
                for (let i in this.remoteStream) {
                    this.remoteStream[i].setAudioVolume(a)
                }
            },
            isGetVoice(item) {
                // mikeStatus为true时表示当前静音，为false时表示当前未闭麦
                if (item.mikeStatus) {
                    if (item.user_uuid == this.$store.state.login.user.uuid) {
                        if (this.isAudio) this.localStatus ? this.localStream.unmuteAudio() : ""
                    } else {
                        this.remoteStream[item.user_uuid] ? this.remoteStream[item.user_uuid].unmuteAudio() : ''
                    }
                } else {
                    if (item.user_uuid == this.$store.state.login.user.uuid) {
                        if (this.isAudio) this.localStatus ? this.localStream.muteAudio() : ""
                    } else {
                        this.remoteStream[item.user_uuid] ? this.remoteStream[item.user_uuid].muteAudio() : ''
                    }
                }
                let user_list = this.$store.state.chat.room_user_show_list
                user_list[this.nowRoomId][item.user_uuid].mikeStatus = !item.mikeStatus
                this.$store.state.chat.room_user_show_list = Object.assign({}, this.$store.state.chat.room_user_show_list, user_list)
            },
            createChatRoom(identity, state) {
                /**
                 创建客户端(在state中game.user_audio_room获取房间id)
                 进入房间
                 **/
                // 关闭trtc控制台log输出
                TRTC.Logger.setLogLevel(5);
                this.audioClient = TRTC.createClient({
                    mode: "live",
                    sdkAppId: state.app_id,
                    userId: state.user_info.user_uuid,
                    userSig: state.user_sig,
                    useStringRoomId: true
                })
                let roomId = this.nowRoomId
                // 进入房间
                this.audioClient.join({ roomId, role: 'anchor' }).catch(error => {
                    console.log('主播进房失败------' + error);
                    this.$store.commit('JOIN_STATUS',false)
                }).then(() => {
                    this.firstJoinRoom = true
                    // 推送本地流
                    this.localStream = TRTC.createStream({userId: state.user_info.user_uuid, audio: true, video: false});
                    this.localStream.initialize().catch(error => {
                        this.localStatus = false
                        console.log('初始化本地流失败------' + error);
                    }).then(() => {
                        this.localStream.play('local_stream');
                        this.audioClient.publish(this.localStream).catch(error => {
                            console.log('本地流发布失败------' + error);
                        }).then(() => {
                            console.log('本地流发布成功------');
                            this.localStatus ? this.localStream.muteAudio() : ''
                            // if (!identity) {
                            //     console.log('观众角色取消麦克风-------')
                            //     this.localStatus ? this.localStream.muteAudio() : ''
                            // }
                        });
                    });
                });
                // 订阅获取远端流
                this.audioClient.on('stream-added', event => {
                    const remoteStream = event.stream;
                    console.log('远端流增加------' + remoteStream.getUserId());
                    this.RSId.push(remoteStream.getUserId());
                    //订阅远端流
                    this.audioClient.subscribe(remoteStream);
                });
                this.audioClient.on('stream-removed', event => {
                    const remoteStream = event.stream
                    console.log('远端流减少------' + remoteStream.getUserId())
                    let index = this.RSId.indexOf(remoteStream.getUserId())
                    this.RSId.splice(index, 1)
                    delete this.remoteStream[remoteStream.getUserId()]
                });
                this.audioClient.on('stream-subscribed', event => {
                    const remoteStream = event.stream;
                    console.log('远端流订阅成功------' + remoteStream.getUserId());
                    this.remoteStream[remoteStream.getUserId()] = remoteStream
                    // 播放远端流
                    remoteStream.play('remote_stream_' + remoteStream.getUserId());
                    this.remoteStream[remoteStream.getUserId()].setAudioVolume(this.voiceValue/100)
                });
                this.audioClient.on('mute-audio', event => {
                    console.log('关闭本地麦',event.userId)
                });
                this.audioClient.on('unmute-audio', event => {
                    console.log('开启本地麦',event.userId)
                });
                this.audioClient.on('audio-volume', event => {
                    this.audioArray = []
                    event.result.forEach(({ userId, audioVolume, stream }) => {
                        if (audioVolume > 0) {
                            this.audioArray.push(userId)
                        }
                    })
                });
                // 开启音量回调，并设置每 1000ms 触发一次事件
                this.audioClient.enableAudioVolumeEvaluation(200);
            },
            quiteChatRoom(type) {
                // 主播身份先取消本地推流
                if (this.allowAudio && this.localStatus) {
                    this.audioClient.unpublish(this.localStream).then(()=>{
                        console.log('取消本地流发布成功------')
                        this.audioClient.leave().catch(error => {
                            console.log('退房失败------' + error)
                            // 错误不可恢复，需要刷新页面。
                        }).then(() => {
                            console.log('退房成功------')
                            // 退房成功，可再次调用client.join重新进房开启新的通话。
                            type == 1 ? this.chatNum = 0 : ''
                            this.nsConn.conn.close()
                        })
                    })
                } else {
                    this.audioClient.leave().catch(error => {
                        console.log('退房失败------' + error);
                        // 错误不可恢复，需要刷新页面。
                    }).then(() => {
                        console.log('退房成功------');
                        // 退房成功，可再次调用client.join重新进房开启新的通话。
                        type == 1 ? this.chatNum = 0 : ''
                        this.nsConn.conn.close()
                    })
                }
            },
            sendMessage() {
                if (!this.isText) return
                if (this.chatText) {
                    let atUser = []
                    for (let i in this.atUser) {
                        atUser.push(i)
                    }
                    this.checkMessage(this.chatText, atUser)
                } else {
                    this.$store.commit('MESSAGE_TEXT', '聊天内容不能为空')
                }
            },
            callback_onWsConnected(nsConn) {
                this.nsConn = nsConn;
                // this.chatStatus = true
            },
            callback_onWsDisConnected() {
                this.RSId = []
                this.chatStatus = false;
                this.firstJoinRoom = false
                this.quiteChatRoom(1)
            },
            commentEmojiDataHandle() {
                /**
                 * count： 表情分页数据处理
                 * space： 控制一页表情最大数
                 */
                let count = -1,
                    space = 36;

                this.emojis.map((item, index) => {
                    if (index % space == 0) {
                        this.commentEmojiPage.push([]);
                        count++;
                    }
                    this.commentEmojiPage[count].push(item);
                });
            },
            commentInputEmoji(item) {
                // 点击表情，对应文本框事件
                const myField = this.$refs.commentEle.$el.children[0];
                // 是否已输入，输入进入处理，未输入过直接赋值
                if (myField.selectionStart || myField.selectionStart === 0) {
                    let startPos = myField.selectionStart;
                    let endPos = myField.selectionEnd;
                    // 0到光标起始位置 + 拼接的内容 + 光标起始到结束位置
                    this.chatText = myField.value.substring(0, startPos) + item + myField.value.substring(endPos, myField.value.length)
                    // 重新设定光标位置
                    this.$nextTick(() => {
                        myField.focus();
                        myField.setSelectionRange(
                            endPos + item.length,
                            endPos + item.length
                        );
                    });
                } else {
                    this.textarea = item;
                }
            },
            hideModular() {
                document.addEventListener('click', (e) => {
                    let emo = document.querySelector(".gameChat-em");
                    let voice = document.querySelector(".gameChat-voice");
                    let voice1 = document.querySelector(".gameChat-noVoice");
                    let contextChatMenu = document.querySelector(".gameChat-contextChatMenu");
                    let contextInfoMenu = document.querySelector(".gameChat-listItem");

                    emo = emo == null ? true :  !emo.contains(e.target);
                    voice = voice == null ? true :  !voice.contains(e.target);
                    voice1 = voice1 == null ? true :  !voice1.contains(e.target);
                    contextChatMenu = contextChatMenu == null ? true :  !contextChatMenu.contains(e.target);
                    contextInfoMenu = contextInfoMenu == null ? true :  !contextInfoMenu.contains(e.target);
                    emo ? this.emojiStatus = false : ''
                    voice && voice1 ? this.voiceStatus = false : ''
                    contextChatMenu ? this.contextChatMenuStatus = false : ''
                    contextInfoMenu ? this.contextInfoMenuStatus = false : ''
                })
            },
            deepClone(obj) {
                let objClone =  Array.isArray(obj) ? [] : {};
                if (obj && typeof obj === 'object') {
                    for(let key in obj){
                        if (obj[key] && typeof obj[key] === 'object'){
                            objClone[key] = this.deepClone(obj[key]);
                        }else{
                            objClone[key] = obj[key]
                        }
                    }
                }
                return objClone;
            },
            onVisibilityChange() {
                if (document.hidden) {
                    if (this.$store.state.chat.related_news_counts != 0) {
                        clearInterval(this.timerFour)
                        this.timerFour = setInterval(()=>{
                            document.title == this.documentTitle ? document.title = '您有新消息，请查看！' : document.title = this.documentTitle
                        },1000)
                    } else {
                        clearInterval(this.timerFour)
                    }
                } else {
                    clearInterval(this.timerFour)
                    document.title = this.documentTitle
                    this.chatStatus ? this.$store.commit('RELATED_NEWS_COUNTS',true) : ''
                }
            },

            // 监听设备拔插
            async changeDevice () {
                const devices = await TRTC.getDevices();
                // 4. 新增的设备列表
                const devicesAdded = devices.filter(device => this.prevDevices.findIndex(({ deviceId }) => device.deviceId === deviceId) < 0);
                // 5. 移除的设备列表
                const devicesRemoved = this.prevDevices.filter(prevDevice => devices.findIndex(({ deviceId }) => prevDevice.deviceId === deviceId) < 0);
                if (devicesAdded.length > 0) {
                    this.handleDevicesAdded(devicesAdded);
                }
                if (devicesRemoved.length > 0) {
                    this.handleDevicesRemoved(devicesRemoved);
                }
                this.prevDevices = devices;
            },
            // 设备加入
            handleDevicesAdded (devicesAdded) {
                devicesAdded.forEach(device => {
                    if (device.kind === 'audioinput') {
                        console.log('新增设备', devicesAdded)
                        if (this.localStatus) {
                            this.localStream.switchDevice('audio', devicesAdded[0].deviceId)
                        } else {

                        }
                        // 提示用户检测到新麦克风插入。若用户需要切换到新设备，可以调用 localStream.switchDevice 接口切换设备
                    }
                })
            },
            // 设备移除
            handleDevicesRemoved (devicesRemoved) {
                devicesRemoved.forEach(device => {
                    if (device.kind === 'audioinput') {
                        if (this.isCurrentMicrophoneRemoved(device.deviceId)) {
                            // 当前正在使用的麦克风被拔出
                            console.log('移除设备', devicesRemoved)
                        }
                    }
                })
            },
            // 检测是否当前正在使用的麦克风设备被拔出
            // microphoneIdRemoved 是被移除的麦克风 deviceId
            isCurrentMicrophoneRemoved (microphoneIdRemoved) {
                const audioTrack = this.localStream.getAudioTrack();
                if (audioTrack && audioTrack.getSettings().deviceId === microphoneIdRemoved) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        beforeDestroy() {
            clearTimeout(this.timer)
            clearTimeout(this.timerText)
            clearTimeout(this.timerTwo)
            clearTimeout(this.timerThree)
            this.quiteChatRoom(1)
        }
    }
</script>

<style scoped lang="scss">
    p,h6,div,span,i,ul,li,h2 {margin: 0;padding: 0}
    .gameChat {
        position: fixed;
        bottom: 8px;left: 8px;
        z-index: 20;
        font-family: PingFangSC-Regular, PingFang SC;
        .gameChat-entry {
            width: 320px;
            height: 40px;
            background: #2F333D;
            border-radius: 8px;
            cursor: pointer;
            i:nth-of-type(1) {
                float: left;
                width: 24px;
                height: 24px;
                background: url("../../assets/images/game/gameChat-home.png") center no-repeat;
                background-size: cover;
                margin: 8px 16px;
            }
            span {
                float: left;
                height: 22px;
                font-size: 16px;
                font-weight: 400;
                color: #E6E6E6;
                line-height: 22px;
                margin-top: 9px;
            }
            sup {
                background: #FF0000;
                color: #ffffff;
                height: 25px;
                border-radius: 12px;
                font-size: 12px;
                font-weight: bolder;
                padding: 0 5px;
                line-height: 20px;
                margin-left: 15px;
            }
            i:nth-of-type(2) {
                float: right;
                width: 10px;
                height: 16px;
                background: url("../../assets/images/game/gameChat-up.png") center no-repeat;
                background-size: cover;
                margin: 12px 16px;
                transform: rotate(-90deg);
            }
        }
        .gameChat-con {
            width: 706px;
            height: 546px;
            background: rgba(47, 51, 61, 0.8);
            border-radius: 8px;
            border: 1px solid #2F333D;
            position: relative;
            .gameChat-header {
                width: 706px;
                height: 40px;
                background: rgba(51, 56, 68, 0.8);
                border-radius: 8px 8px 0px 0px;
                i:nth-of-type(1) {
                    float: left;
                    width: 24px;
                    height: 24px;
                    background: url("../../assets/images/game/gameChat-home.png") center no-repeat;
                    background-size: cover;
                    margin: 7px 8px 8px 16px;
                }
                span {
                    float: left;
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 25px;
                    margin-top: 7px;
                }
                img:nth-of-type(1) {
                    float: right;
                    width: 12px;
                    height: 12px;
                    background: url("../../assets/images/game/gameChat-x.png") center no-repeat;
                    background-size: cover;
                    margin: 14px 16px 14px 24px;
                    cursor: pointer;
                }
                img:nth-of-type(2) {
                    float: right;
                    width: 12px;
                    height: 12px;
                    background: url("../../assets/images/game/gameChat-min.png") center no-repeat;
                    background-size: cover;
                    margin-top: 14px;
                    cursor: pointer;
                }
            }
            .gameChat-aside {
                width: 64px;
                height: 506px;
                background: #3B404E;
                border-radius: 0px 0px 0px 4px;
                position: absolute;
                top: 40px;left: 0;
                li {
                    width: 64px;
                    height: 40px;
                    margin-top: 8px;
                    padding: 0px 5px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFFAF4;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                    overflow:hidden;
                    white-space:nowrap;
                    text-overflow:ellipsis;
                    position: relative;
                    span {
                        position: absolute;
                        top: 3px;right: 2px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #FF0000;
                    }
                    i {
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        right: 1px;top: 17px;
                        background: url("../../assets/images/game/gameChat-x.png") no-repeat center;
                        background-size: cover;
                        display: none;
                    }
                }
                .li-active {
                    background: #2F333D;
                }
                .li-active:hover {
                    i {
                        display: block;
                    }
                }
            }
            .gameChat-airing {
                width: 376px;
                height: 56px;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.16) 50%, rgba(255, 255, 255, 0.02) 100%);
                position: absolute;
                left: 74px;top: 48px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding-top: 8px;
                i {
                    width: 14px;
                    height: 12px;
                    background: url("../../assets/images/game/gameChat-airing.png") center no-repeat;
                    background-size: cover;
                    margin: 4px 10px 0 0;
                }
                p {
                    width: 336px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
                    span {
                        color: #F7B500 ;
                    }
                }
            }
            .gameChat-box {
                width: 376px;
                height: 338px;
                position: absolute;
                left: 74px;top: 108px;
                border-bottom: 1px solid #484F5F;
                overflow-y: auto;
                scrollbar-width: none; /* firefox */
                -ms-overflow-style: none; /* IE 10+ */
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .gameChat-boxItem,.gameChat-boxItemExtra,.gameChat-atItem{
                    padding: 6px 12px 6px 8px;
                    background: #2F333D;
                    border-radius: 4px;
                    margin: 6px 0 6px 8px;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
                    word-break: break-all;
                    word-wrap: break-word;
                    cursor: pointer;
                    position: relative;
                    span {
                        color: #FAD8A1;
                        margin-right: 10px;

                    }
                    .own {
                        color: #ADADB0;
                    }
                    i {
                        position: absolute;
                        bottom: 8px;right: 5px;
                        width: 16px;
                        height: 16px;
                        background: url("../../assets/images/game/gameChat-sendfail.png") no-repeat center;
                        background-size: cover;
                    }
                    .sending {
                        background: url("../../assets/images/game/gameChat-sending.png") no-repeat center;
                        background-size: cover;
                        transform-origin: center center;
                        animation: turn 2s linear infinite;
                    }
                }
                .gameChat-boxItemExtra {
                    padding: 6px 25px 6px 8px;
                }
                .gameChat-atItem {
                    color: #D5A561!important;
                }
            }
            .gameChat-box::-webkit-scrollbar {
                width: 4px;
                height: 1px;
            }
            .gameChat-box::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #ADADB0;
            }
            .gameChat-box::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background: #606062;
            }
            .gameChat-failBox {
                width: 376px;
                height: 306px;
                position: absolute;
                left: 74px;top: 108px;
                border-bottom: 1px solid #484F5F;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img {
                    width: 48px;
                    height: 42px;
                }
                span {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #D5A561;
                    line-height: 20px;
                    margin: 8px 0 16px;
                }
                p {
                    width: 290px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #E6E6E6;
                    line-height: 20px;
                }
                div {
                    width: 148px;
                    height: 40px;
                    background: #FAD8A1;
                    border-radius: 20px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #351B08;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                    margin-top: 16px;
                }
            }
            .gameChat-contextChatMenu {
                width: 120px;
                /*height: 122px;*/
                background: #FFFFFF;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
                border-radius: 8px;
                padding: 10px 8px;
                li {
                    width: 104px;
                    height: 32px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #606062;
                    line-height: 32px;
                    text-align: center;
                    margin-bottom: 3px;
                    cursor: pointer;
                }
                .norecall {
                    color: #d1ccc5;
                }
                .norecall:hover {
                    background: #FFFFFF;
                }
                li:hover {
                    background: #E6E6E6;
                }
                li:nth-last-of-type(1) {
                    margin-bottom: 0;
                }
            }
            .gameChat-roomIdInput {
                position: absolute;
                top: 48px;right: 48px;
                /deep/ input {
                    width: 200px;
                    height: 32px;
                    background: #2F333D;
                    border-radius: 4px;
                    border: none;
                    text-outline: none;
                    font-size: 14px;
                    font-weight: 400;
                    color: #ADADB0;
                }
                img {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 6px;right: 8px;
                    cursor: pointer;
                }
            }
            .gameChat-createRoom {
                width: 32px;
                height: 32px;
                background: #2F333D;
                border-radius: 4px;
                position: absolute;
                top: 48px;right: 8px;
                img {
                    width: 20px;
                    height: 20px;
                    margin: 6px;
                    cursor: pointer;
                }
            }
            .gameChat-list {
                width: 240px;
                height: 450px;
                background: rgba(59, 64, 78, 0.8);
                border-radius: 8px;
                position: absolute;
                right: 8px;bottom: 8px;
                overflow-y: auto;
                scrollbar-width: none; /* firefox */
                -ms-overflow-style: none; /* IE 10+ */
                .gameChat-listHeader {
                    height: 22px;
                    margin: 13px 0 4px 16px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    i {
                        width: 16px;
                        height: 16px;
                        background: url("../../assets/images/game/gameChat-people.png") center no-repeat;
                        background-size: cover;
                        margin: 3px 8px 0 0;
                    }
                    span {
                        height: 22px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #E6E6E6;
                        line-height: 22px;
                        margin-right: 16px;
                    }
                    img:nth-of-type(1) {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 1px;right: 16px;
                        cursor: pointer;
                    }
                    img:nth-of-type(2) {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 1px;right: 45px;
                        cursor: pointer;
                    }
                }
                .gameChat-listInput {
                    position: relative;
                    margin: 8px;
                    /deep/ input {
                        width: 224px;
                        height: 32px;
                        background: #2F333D;
                        border-radius: 4px;
                        border: none;
                        text-outline: none;
                        font-size: 12px;
                        font-weight: 400;
                        color: #ADADB0;
                    }
                    img {
                        position: absolute;
                        right: 8px;top: 8px;
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                    }
                }
                .gameChat-listItem {
                    height: 24px;
                    margin: 16px;
                    cursor: pointer;
                    position: relative;
                    img  {
                        float: left;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                    }
                    span {
                        position: absolute;
                        bottom: 0px;left: 15px;
                        width: 12px;
                        height: 12px;
                    }
                    .ownerIcon {
                        background: url("../../assets/images/game/gameChat-homeericon.png") center no-repeat;
                        background-size: cover;
                    }
                    .adminIcon {
                        background: url("../../assets/images/game/gameChat-adminicon.png") center no-repeat;
                        background-size: cover;
                    }
                    p {
                        float: left;
                        margin-left: 16px;
                        height: 24px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 24px;
                    }
                    i {
                        float: right;
                        width: 20px;
                        height: 20px;
                        margin-top: 2px;
                        cursor: pointer;
                    }
                    .mikeIcon {
                        background: url("../../assets/images/game/gameChat-peoplemike.png") center no-repeat;
                        background-size: cover;
                    }
                    .ban-text {
                        background: url("../../assets/images/game/gameChat-bantext.png") center no-repeat;
                        background-size: cover;
                    }
                    .kickOut {
                        background: url("../../assets/images/game/gameChat-kick.png") center no-repeat;
                        background-size: cover;
                    }

                    i:nth-of-type(2), i:nth-of-type(3){
                        margin-right: 8px;
                    }
                    .noMike {
                        background: url("../../assets/images/game/gameChat-peoplenovoice.png") center no-repeat!important;
                        background-size: cover!important;
                    }
                    .banMike {
                        background: url("../../assets/images/game/gameChat-banvoice.png") center no-repeat!important;
                        background-size: cover!important;
                    }
                    .shieldMike {
                        background: url("../../assets/images/game/gameChat-shieldvoice.png") center no-repeat!important;
                        background-size: cover!important;
                    }
                }
            }
            .gameChat-list::-webkit-scrollbar {
                width: 4px;
                height: 1px;
            }
            .gameChat-list::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #ADADB0;
            }
            .gameChat-list::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background: #606062;
            }
            .gameChat-contextInfoMenu {
                width: 216px;
                height: 88px;
                background: #FFFFFF;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
                border-radius: 8px;
                padding: 8px;
                section {
                    height: 40px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 8px;
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                    h2 {
                        height: 40px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #D5A561;
                        line-height: 40px;
                    }
                }
                div {
                    height: 24px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    li {
                        width: 64px;
                        height: 24px;
                        background: #E6E6E6;
                        cursor: pointer;
                        margin-right: 4px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #606062;
                        line-height: 24px;
                        text-align: center;
                    }
                    .noban {
                        color: #ADADB0;
                    }
                    .noban:hover {
                        background: #E6E6E6;
                    }
                    li:nth-last-of-type(1) {
                        margin-right: 0;
                    }
                    li:hover {
                        background: #FAD8A1;
                    }
                }
            }
            .gameChat-settingGroup {
                width: 376px;
                height: 47px;
                position: absolute;
                left: 74px;bottom: 56px;
                .gameChat-em {
                    cursor: pointer;
                    float: left;
                    width: 24px;
                    height: 24px;
                    background: url("../../assets/images/officialWebsite/game/game-em1.png");
                    background-size: 24px 24px;
                    margin: 15px 8px 8px;
                }
                .gameChat-emoji {
                    width: 285px;
                    height: 175px;
                    position: absolute;
                    top: -175px;
                    left: 5px;
                    padding: 10px;
                    background: #fff;
                    z-index: 15;
                    border-radius: 8px;
                    box-shadow: 0px 2px 8px 0px rgba(173, 173, 176, 0.2);
                    ul {
                        padding-left: 0px;
                        margin-bottom: 0px;
                        li {
                            z-index: 1;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            flex: 1;
                            div {
                                width: 24px;
                                height: 24px;
                                margin: 5px 0 5px 5px;
                                cursor: pointer;
                            }
                        }
                    }
                    .emojiNav_btn {
                        position: absolute;
                        left: 0;
                        bottom: 10px;
                        width: 100%;
                        height: 20px;
                        text-align: center;
                        > a {
                            font-size: 12px;
                            width: 14px;
                            height: 14px;
                            margin: 0px 5px;
                            cursor: pointer;
                        }
                        > a:hover {
                            color: #fad8a1;
                        }
                    }
                }
                .gameChat-voice, .gameChat-noVoice {
                    cursor: pointer;
                    float: left;
                    width: 24px;
                    height: 24px;
                    background: url("../../assets/images/game/gameChat-voice.png");
                    background-size: 24px 24px;
                    margin: 15px 8px 8px;
                    position: relative;
                    .gameChat-voiceSetting {
                        width: 30px;
                        height: 120px;
                        border-radius: 5px;
                        background: #222222;
                        position: absolute;
                        top: -130px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 0;
                        /deep/ .el-slider__bar {
                            background-color: #D5A561;
                        }
                        /deep/ .el-slider__button {
                            border-color: #FFFFFF;
                        }
                        /deep/ .el-slider__runway {
                            background-color: #ADADB0;
                        }
                    }
                }
                .gameChat-noVoice {
                    background: url("../../assets/images/game/gameChat-novoice.png");
                    background-size: 24px 24px;
                }

            }
            .gameChat-btnGroup {
                width: 376px;
                height: 40px;
                position: absolute;
                left: 74px;bottom: 16px;
                display: flex;
                padding: 0 8px;
                justify-content: space-between;
                .gameChat-input {
                    /deep/ input {
                        width: 248px;
                        height: 40px;
                        border-radius: 32px;
                        background: #484F5F;
                        border: none;
                        font-size: 14px;
                        color: #ffffff;
                        line-height: 40px;
                        resize: none;
                    }
                }
                .gameChat-btn, .gameChat-noBtn {
                    width: 88px;
                    height: 40px;
                    background: linear-gradient(154deg, #FFCC84 0%, #FFB44D 100%);
                    border-radius: 20px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #351600;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                }
                .gameChat-noBtn {
                    background: #ADADB0;
                    color: #FFFFFF;
                }
            }
            .gameChat-reportMenu {
                width: 288px;
                background: #FFFFFF;
                border-radius: 8px;
                position: absolute;
                top: 40px;left: 167px;
                padding: 24px;
                position: relative;
                .gameChat-reportRadio {
                    /deep/ .el-radio {
                        /*width: 100%;*/
                        height: 22px;
                        margin-bottom: 10px;
                    }
                    /deep/ .el-radio__label {
                        font-size: 16px;
                        font-weight: 400;
                        color: #000000;
                        line-height: 22px;
                    }
                    /deep/ .el-radio__input.is-checked .el-radio__inner {
                        border-color: #FA6400;
                        background: #FA6400;
                    }
                }
                .gameChat-reportInput {
                    /deep/ textarea {
                        width: 240px;
                        height: 72px;
                        border: 1px solid #979797;
                        resize: none;
                    }
                }
                .gameChat-reportBtn {
                    cursor: pointer;
                    width: 98px;
                    height: 32px;
                    background: #FA6400;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 32px;
                    text-align: center;
                    margin: 16px auto 0;
                }
                .gameChat-noReportBtn {
                    cursor: pointer;
                    width: 98px;
                    height: 32px;
                    background: #E6E6E6;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #606062;
                    line-height: 32px;
                    text-align: center;
                    margin: 16px auto 0;
                }
                i {
                    position: absolute;
                    top: 8px;right: 8px;
                    width: 16px;
                    height: 16px;
                    background: url("../../assets/images/game/gameChat-x.png") no-repeat center;
                    background-size: cover;
                    cursor: pointer;
                }
            }
        }

        @keyframes turn {
            from {
                transform: rotate(360deg);
            }

            to {
                transform: rotate(0);
            }
        }
    }
</style>

<template>
    <div class="game" :style="{height:pageHeight}" @keyup.enter="inputSure" @keyup.esc="()=>{commentStatus=false,$store.commit('GAME_INPUT',false)}" tabindex="-1">
        <div class="game-con" v-show="loadingStatus">
            <div class="game_box">
                <div class="firstBorder">
                    <div class="game_container" :style="{width:gameContainerWidth, height:gameContainerHeight}">
                        <div id="game-container" ref="gameContainer"></div>
                        <div v-if="ali_status" class="loading" :style="{width:gameContainerWidth, height:gameContainerHeight}">
                            <progress class="progress" max="100"></progress>
                        </div>
                        <div :class="[btnType==1 ? 'game-btn' : btnType==2 ? 'minGame-btn' : btnType==3 ? 'game-fullBtn gameShow' : 'game-fullBtn gameHide']">
                            <img v-if="isFullScreen" src="@/assets/images/game/game-setting.png" alt="传奇云游戏" @click="fullScreenSetting">
                            <img v-else src="@/assets/images/game/game-setting.png" alt="传奇云游戏" @click="()=>{settingShow=!settingShow;qualityInfo=false;voiceSlider=false}">
                            <transition name="Fade">
                                <ul v-if="settingShow">
                                    <li>
                                        <i></i>
                                        <h6 v-if="rtt<=400">{{rtt}}ms</h6>
                                        <h6 v-else style="color: red">{{rtt}}ms</h6>
                                        <p>延迟</p>
                                    </li>
                                    <li v-if="isFullScreen" @click="exit_full_screen">
                                        <i></i>
                                        <p>全屏</p>
                                    </li>
                                    <li v-else @click="request_full_screen">
                                        <i></i>
                                        <p>全屏</p>
                                    </li>
                                    <li @click.stop="()=>{qualityInfo=!qualityInfo}" style="display: none">
                                        <i></i>
                                        <p>画质</p>
                                        <div v-if="qualityInfo" @click="selectQuality($event)">
                                            <section>超清</section>
                                            <section>高清</section>
                                            <section>标清</section>
                                        </div>
                                    </li>
                                    <li @click.stop="()=>{voiceSlider=!voiceSlider}" v-show="(cloud_provider == 'tencent'||cloud_provider == 'ali') && gameSuccess">
                                        <i v-show="voiceStatus != 0"></i>
                                        <i v-show="voiceStatus == 0"></i>
                                        <p>声音</p>
                                        <div v-if="voiceSlider" class="game-voice" @click.stop="()=>{}">
                                            <el-slider v-model="voiceStatus" :show-tooltip="false" style="width: 160px" @input="changeVoiceStatus"></el-slider>
                                            <span>{{voiceStatus}}</span>
                                        </div>
                                        <span v-if="voiceSlider" class="arrow-right"></span>
                                    </li>
                                    <li v-show="supportRecharge" @click="openRechargeLink()">
                                        <i></i>
                                        <p>充值</p>
                                    </li>
                                    <li @click="()=>{$store.commit('GAME_INPUT',!$store.state.game.game_input)}" v-show="gameState !== 'game-closed' && gameSuccess">
                                        <i></i>
                                        <p>输入</p>
                                    </li>
                                    <template v-if="!keepStatus">
                                        <li @click="archive" v-show="getArchive && gameState !=='game-closed'">
                                            <i></i>
                                            <p>存档</p>
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li class="archiveSuc" @click="archive" v-show="getArchive && gameState !=='game-closed'">
                                            <i></i>
                                            <p>存档</p>
                                        </li>
                                    </template>
                                    <li class="game-DI" @click.stop="()=>{feedDialog=!feedDialog}">
                                        <i></i>
                                        <p>反馈</p>
                                    </li>
                                    <li @click="close_game" v-show=" gameState !=='game-closed' ">
                                        <i></i>
                                        <p>退出</p>
                                    </li>
                                </ul>
                            </transition>
                        </div>
                        <div class="game_input" v-if="$store.state.game.game_input && gameState !=='game-closed'">
                            <input type="text" id="game_input" v-model="gameInputMsg" v-focus @input="inputText()"></input>
                            <button @click="()=>{$store.commit('GAME_INPUT',false)}">关闭</button>
                        </div>
                        <NewCloudGameLauncher
                                ref="launchGame"
                                @startCloudGame="startCloudGame"
                                @changeDialogStatus="changeDialogStatus"
                                @stopGamePageAnimation="stopGamePageAnimation"
                                @exit_full_screen="exit_full_screen"
                                :dialogStatus="dialogStatus"
                        ></NewCloudGameLauncher>
                        <FeedBack :feedDialog="feedDialog" @controlDialog="(val)=>feedDialog = val"></FeedBack>
                        <Loading v-if="isFullScreen"></Loading>
                        <GameChat v-if="game_id == '9030'" :wsServer="wsChatServer"></GameChat>
                    </div>
                    <div class="game_title">
                        <h2>{{selectedGameName}}</h2>
                        <div class="game_areas" v-if="gameArea">
                            <p><span>{{gameArea}}</span></p>
                        </div>
                        <div class="game_timeCount">
                            <p>本局时长 <span>{{thisTimeCount | timeFormat}}</span></p>
                            <p></p>
                            <p>累计时长 <span><i>{{gameTimeCount | hoursFormat(this)}}</i>小时<i>{{gameTimeCount | minuteFormat}}</i>分钟</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="[btnType == 1 ? 'game-comments' : btnType == 2 ? 'game-comment' : '']"  @click.stop="()=>{commentStatus=!commentStatus}">查看评论</div>
        </div>
        <div class="game-footer" v-if="!isSdo || (isSdo && game_id != '905')">
            <a target="_blank" :href="$store.state.baseUrl">传奇云游戏</a>
            <span>Copyright © 2020-2022 上海天游软件有限公司. All rights Reserved.</span>
            <p>抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
        </div>
        <SideComment :commentStatus="commentStatus" @changeCommentStatus="changeCommentStatus"></SideComment>
    </div>
</template>

<script>
    import helper from "@/utils/helper";
    import gameCommonParam from '@/mixins/gameCommonParam';
    import commonRequest from '@/utils/common'
    import GameChat from "../../components/gameChat/GameChat";

    export default {
        name: "CloudGame",
        components: { GameChat },
        mixins: [gameCommonParam],
        data(){
            return{
                commentStatus: false, // 侧边评论
                feedDialog: false, // 反馈弹窗
                isFullScreen: true, // 是否全屏
                loadingStatus: false,
                gameAreas: '', // 当前游戏区
                thisTimeCount: '', // 本次时长
                gameTimeCount: 0, // 累计时长
                btnType: 1, // 大屏小屏按钮位置 1右侧 2上方 3左侧
                settingShow: true, // 菜单栏按钮显示
                qualityInfo: false, // 画质选择显示
                voiceStatus: 100, // 游戏声音状态
                voiceSlider: false,
                gameContainerWidth: '1280px!important', // 游戏容器宽度
                gameContainerHeight: '740px!important', // 游戏容器高度
                initGameWidth: 1280, // 云游戏原始宽度
                initGameHeight: 700, // 云游戏原始高度
                gameWidth: 1280, // 云游戏容器宽度
                gameHeight: 700, // 云游戏容器高度
                pageHeight: '', // 网页整体高度
                aspectRatio: 9/16, // 游戏容器宽高比
                game_id: '', // 当前游戏id
                wsToken: '',
                wsChatServer: '', // 语音聊天房ws
                cloudApp: null,
                _onnine: null,
                acgCoreManager: null,
                acgInteractManager: null,
                network_info: '', // 链接信息
                rtt: '-', // 延迟
                bitrate: '-', // 码率
                session_id: '', // sessionId
                _ws_connection: null,
                daoyuAppId: 199, // 叨鱼端使用
                msgTitle: '',
                msgWarningClass: '',
                isSdo: false,
                gameName: '',
                daoyuTicket: '',
                copyHttp: document.URL, // 网页地址
            }
        },
        created() {
            this.game_id = this.$route.params.id;
            // 获取叨鱼登录的信息
            if (this.$route.name == 'Sdo') {
                this.isSdo = true;
            }
        },
        mounted() {
            // 点击其他地方隐藏设置栏
            document.addEventListener('click', (e) => {
                let btn = document.querySelector(".game-voice");
                let btn1 = document.querySelector("#game_input");
                if(btn){
                    if(!btn.contains(e.target)){
                        this.voiceSlider = false
                    }
                }
                if(btn1){
                    if(!btn1.contains(e.target)){
                        btn1.focus()
                    }
                }
            })
            this.gameReady();//准备启动游戏
            window.addEventListener('resize', this.onWindowResized);
        },
        filters: {
            timeFormat(value){
                let s = value%60
                let m = (value-s)/60%60
                let h = Math.floor((value-s)/60/60)
                h<10 ? h='0'+h : h
                m<10 ? m='0'+m : m
                s<10 ? s='0'+s : s
                return h + ':' + m + ':' + s
            },
            hoursFormat(value) {
                let t = Math.floor((value-(value%60))/60/60)
                t<10 ? t='0'+t : t
                return t
            },
            minuteFormat(value) {
                let t = Math.floor((value-(value%60))/60%60)
                t<10 ? t='0'+t : t
                return t
            }
        },
        watch:{
            isTencentMobileGame(state) {
                if(state){
                    let videoStream = document.querySelector('#video-stream')
                    videoStream.style.transform = 'rotate(-90deg)';
                }
            }
        },
        computed: {
            //游戏输入框原有的信息
            gameInputMsg: {
                get () {
                    return this.$store.state.game.game_input_msg ?? '';
                },
                set (value) {
                    this.$store.commit('GAME_INPUT_MSG', value);
                }
            }
        },
        methods: {
            changeCommentStatus() {
                this.commentStatus = false
            },
            // 全屏工具栏控制
            fullScreenSetting() {
                if (this.btnType == 3 || this.btnType == 4) {
                    this.btnType == 3 ? this.btnType = 4 : this.btnType = 3
                    this.btnType == 3 ? this.settingShow=true : (this.settingShow=false,this.qualityInfo=false,this.voiceSlider=false)
                }
            },
            // 初始化页面参数
            initPageParams(res) {
                let resultCode = res.code;
                let wsServer = '';
                this.loadingStatus = true;
                if (resultCode == 4050) {
                    this.$router.push({
                        name: 'NoSupport',
                        params: {
                            gameId:  this.game_id,
                            copyHttp: this.copyHttp
                        }
                    })
                    return;
                }

                // 叨鱼在我们平台登录失效
                if (resultCode == 40001) {
                    this.onWindowResized();
                    this.skipDaoyuLoginPage();
                    return;
                }

                if (res.status) {
                    this.wsChatServer = res.data.ws_token.ws_chat_server ?? ''
                    this.gameWidth = this.initGameWidth = res.data.game_params.video_width
                    this.gameHeight = this.initGameHeight = res.data.game_params.video_height
                    this.gameName = res.data.game_params.name;
                    this.wsToken = res.data.ws_token.token;
                    this.gameTimeCount = res.data.player_info.gaming_duration
                    wsServer = res.data.ws_token.ws_server;
                    let _user = this.$store.state.login.user;
                    if (this.isSdo && (!_user || (_user && _user.hasOwnProperty('platform') && _user.platform != 'daoyu'))) {
                        commonRequest.request_user_info();
                    }
                }
                this.onWindowResized();
                this.$refs.launchGame.requestStartCloudGame('', this.wsToken, wsServer);
            },
            // 游戏画质选择
            selectQuality(e){
                this.settingShow = false;
            },

            // 游戏全屏
            request_full_screen(){
                let fullScreen = ''
                switch (this.cloud_provider) {
                    case "tencent" :
                        fullScreen = document.querySelector('.game_container')
                        let cloudGamingContainer = document.querySelector('#cloud-gaming-container')
                        cloudGamingContainer.style.alignItems = 'stretch';
                        let videoStream = document.querySelector('#video-stream')
                        videoStream.style.objectFit = 'fill';
                        TCGSDK.setFullscreen(true, fullScreen);
                        break;
                    case "ali" :
                    case "huawei" :
                        fullScreen = document.querySelector('.game_container')
                        this.launchFullscreen(fullScreen);
                        break;
                    case "ub" :
                        fullScreen = document.querySelectorAll('iframe')
                        this.launchFullscreen(fullScreen[0]);
                        break;
                }
            },
            // 退出全屏
            exit_full_screen(){
                if (document.exitFullScreen) {
                    document.exitFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            },
            // 浏览器窗口变化
            onWindowResized(){
                this.isFullScreen = !!(document.fullscreenelement || document.mozFullScreen || document.webkitIsFullScreen || document.webkitFullScreen || document.msFullScreen);
                let t = setTimeout(()=>{
                    clearTimeout(t)
                    this.fullScreenSetting();
                },1000)
                let _onnine= this._onnine;
                // todo  需要整个算法
                this.aspectRatio = this.initGameHeight/this.initGameWidth;
                let browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                let browserHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                if (!this.isFullScreen) {
                    if (browserWidth < this.initGameWidth + 200 && browserWidth > this.initGameWidth) {
                        this.gameWidth = this.initGameWidth;
                        this.btnType = 2
                    } else if (browserWidth > this.initGameWidth + 200) {
                        this.gameWidth = this.initGameWidth;
                        this.btnType = 1
                    } else {
                        this.gameWidth = browserWidth - 50;
                        this.btnType = 2
                    }
                } else {
                    this.isFullScreen ?  this.btnType = 3 : this.btnType = 4
                }
                this.gameHeight = parseInt(this.gameWidth * this.aspectRatio)
                browserHeight<this.gameHeight+260 ? this.pageHeight = this.gameHeight+260 + "px!important" : this.pageHeight='100vh'
                this.gameContainerWidth = this.gameWidth + "px!important"
                this.gameContainerHeight = this.gameHeight + "px!important"
                if(_onnine){
                    _onnine.setSize(this.gameWidth, this.gameHeight);
                }
                if (this.cloud_provider=='ali') {
                    if (this.isFullScreen) {
                        this.acgInteractManager.setSize(browserWidth, browserHeight);
                    } else {
                        this.acgInteractManager.setSize(this.gameWidth, this.gameHeight);
                    }
                }
            },
            // _online游戏全屏
            launchFullscreen(element) {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();//火狐
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();//ie浏览器
                    element.style.height=window.screen.height+"px";
                    element.style.width=document.documentElement.clientWidth+"px";
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullScreen();//谷歌浏览器
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    .game {
        width: 100%;
        //height: 100vh;
        background: url("../../assets/images/game/game-bg.png") no-repeat center;
        background-size: cover;
        position: relative;
        .game-con {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .game_box {
                .firstBorder {
                    border-radius: 5px;
                    border: 1px solid rgba(227,175,109,0.6);
                    background: rgba(0,0,0,0.6);
                    position: relative;
                    .game_container {
                        box-sizing: content-box;
                        margin: 10px;
                        border: 1px solid rgba(227,175,109,1);
                        position: relative;
                        #game_container {
                            box-sizing: content-box;
                        }

                        .progress {
                            appearance: none;
                            background: #eef0f3;
                            border: 0;
                            border-radius: .1rem;
                            color: #FA6400;
                            height: .2rem;
                            position: relative;
                            width: 100%
                        }
                        .progress::-webkit-progress-bar {
                            background: transparent;
                            border-radius: .1rem
                        }
                        .progress::-webkit-progress-value {
                            background: #FA6400;
                            border-radius: .1rem
                        }
                        .progress::-moz-progress-bar {
                            background: #FA6400;
                            border-radius: .1rem
                        }
                        .progress:indeterminate {
                            animation: progress-indeterminate 1.5s linear infinite;
                            background: #eef0f3 linear-gradient(90deg, #FA6400 30%, #eef0f3 0) 0 0/150% 150% no-repeat
                        }
                        .progress:indeterminate::-moz-progress-bar {
                            background: transparent
                        }
                        @keyframes progress-indeterminate {
                            0% {background-position: 200% 0 }
                            to {background-position: -200% 0 }
                        }
                        .loading {
                            position: absolute;
                            top: 0;
                            z-index: 10000;
                            background-image: url("../../assets/images/game/game-loading.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 200px auto;
                        }
                        .loading .progress {
                            position: absolute;
                            bottom: 0;
                            background: #313131;
                        }
                        .loading .progress:indeterminate {
                            background: #313131 linear-gradient(to right, #FA6400 30%, #313131 30%) top left/150% 150% no-repeat;
                        }

                        .game-fullBtn {
                            position: fixed;
                            top: 35px;left: 80px;
                            z-index: 99;
                            img {
                                cursor: pointer;
                                width: 68px;
                                height: 60px;
                                position: absolute;
                                top: 0;right: 0;
                                z-index: 9;
                                transform: rotate(180deg);
                            }
                            ul,li,div,p,span{margin: 0;padding: 0}
                            ul {
                                width: 60px;
                                background: rgba(0, 0, 0, 0.4);
                                border-radius: 0px 0px 100px 100px;
                                position: absolute;
                                right: 8px;top: 30px;
                                z-index: 5;
                                li {
                                    color: #ADADB0;
                                    font-size: 14px;
                                    width: 28px;
                                    margin: 0 auto 25px;
                                    cursor: pointer;
                                    i {
                                        display: block;
                                        width: 24px;
                                        height: 26px;
                                        margin: 0 auto 6px;
                                        background: url("../../assets/images/game/game-icon.png") no-repeat left center;
                                        background-size: 800px 26px;
                                    }
                                    p {text-align: center}
                                }
                                li:hover p{color: #D5A561;}
                                li:nth-of-type(1) {
                                    margin-top: 50px;
                                    width: 34px;
                                    i {
                                        width: 18px;
                                        height: 12px;
                                        background: url("../../assets/images/game/game-ping.png") no-repeat top center;
                                        background-size: cover;

                                    }
                                    h6{
                                        color: #6DD400;
                                        font-size: 12px;
                                        font-weight: 200;
                                        text-align: center;
                                        margin: 0;padding: 0;
                                    }
                                    p {
                                        text-align: center;
                                        margin: 0;padding: 0;
                                    }
                                }
                                li:nth-of-type(1):hover p {
                                    color: #ADADB0;
                                }
                                li:nth-of-type(2) {
                                    i {
                                        background-position: -728px 0px;
                                    }

                                }
                                li:nth-of-type(2):hover i {
                                    background-position: -768px 0px;
                                }
                                li:nth-of-type(3) {
                                    position: relative;
                                    i {
                                        background-position: -8px 0px;
                                    }
                                    div {
                                        width: 58px;
                                        height: 113px;
                                        background-image:url("../../assets/images/game/game-arrow.png");
                                        background-repeat: no-repeat;
                                        background-color: rgba(0,0,0,0.6);
                                        background-position: 4px 51px;
                                        background-size: 12px 12px;
                                        position: absolute;
                                        left: 35px;top: -30px;
                                        section {
                                            width: 34px;
                                            height: 37px;
                                            margin-left: 17px;
                                            line-height: 37px;
                                            text-align: center;
                                            border-bottom: 1px solid #ADADB0;
                                        }
                                        section:last-of-type{
                                            border-bottom: none;
                                        }
                                        section:hover{color: #D5A561}
                                    }
                                }
                                li:nth-of-type(3):hover i {
                                    background-position: -48px 0px;
                                }
                                li:nth-of-type(4) {
                                    position: relative;
                                    i:nth-of-type(1) {
                                        background-position: -168px 0px;
                                    }
                                    i:nth-of-type(2) {
                                        background-position: -288px 0px;
                                    }
                                    .game-voice {
                                        width: 210px;
                                        height: 49px;
                                        border-radius: 5px;
                                        background: #222222;
                                        position: absolute;
                                        top: 0;left: 45px;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        padding: 0 20px;
                                        /deep/ .el-slider__bar {
                                            background-color: #D5A561;
                                        }
                                        /deep/ .el-slider__button {
                                            border-color: #FFFFFF;
                                        }
                                        /deep/ .el-slider__runway {
                                            background-color: #ADADB0;
                                        }
                                        span {
                                            width: 20px;
                                            height: 49px;
                                            font-size: 16px;
                                            font-weight: 400;
                                            color: #FFFFFF;
                                            text-align: center;
                                            line-height: 49px;
                                            margin-left: 15px;
                                        }
                                    }
                                    .arrow-right {
                                        display: block;
                                        width: 0;
                                        height: 16px;
                                        border: 9px solid transparent;
                                        border-right: 9px solid #222222;
                                        position: absolute;
                                        top: 15px;left: 27px;
                                    }
                                }
                                li:nth-of-type(4):hover i:nth-of-type(1) {
                                    background-position: -208px 0px;
                                }
                                li:nth-of-type(4):hover i:nth-of-type(2) {
                                    background-position: -248px 0px;
                                }
                                li:nth-of-type(4) {
                                    i {
                                        background-position: -288px 0px;
                                    }
                                }
                                li:nth-of-type(5) {
                                    i {
                                        width: 26px;
                                        background-position: -327px 0px;
                                    }
                                }
                                li:nth-of-type(6) {
                                    position: relative;
                                    i {
                                        background-position: -529px 0px;
                                    }
                                }
                                li:nth-of-type(6):hover i {
                                    background-position: -569px 0px;
                                }
                                li:nth-of-type(8) {
                                    position: relative;
                                    i {
                                        background-position: -449px 0px;
                                    }
                                    div {
                                        width: 362px;
                                        border-radius: 10px;
                                        background-image:url("../../assets/images/game/game-arrow.png");
                                        background-repeat: no-repeat;
                                        background-color: rgba(0,0,0,0.6);
                                        background-position: center left;
                                        background-size: 12px 12px;
                                        position: absolute;
                                        left: 40px;top: -12px;
                                        section {
                                            width: 340px;
                                            height: 40px;
                                            margin: 0 auto;
                                            border-bottom: 1px solid rgba(96, 96, 98, 0.5);
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            span:nth-child(2) {
                                                margin-left: 5px;
                                                color: #FFFFFF;
                                                font-size: 14px;
                                            }
                                            span:nth-child(1) {
                                                width: 40px;
                                                color: #D5A561;
                                                font-size: 14px;
                                            }
                                        }
                                        section:nth-child(2) {
                                            span:nth-child(2) {
                                                color: red;
                                            }
                                        }
                                        section:nth-child(3) {
                                            border-bottom: none;
                                        }
                                    }
                                }
                                li:nth-of-type(8):hover i {
                                    background-position: -489px 0px;
                                }
                                li:nth-of-type(7) {
                                    i {
                                        background-position: -608px 0px;
                                    }

                                }
                                li:nth-of-type(7):hover i {
                                    background-position: -648px 0px;
                                }
                                .archiveSuc {
                                    i {
                                        background-position: -688px 0px!important;
                                    }
                                }
                                .archiveSuc:hover i{
                                    background-position: -688px 0px!important;
                                }
                                li:last-of-type {
                                    i {
                                        background-position: -410px 0px;
                                    }
                                }
                                li:last-of-type:hover i {
                                    background-position: -370px 0px;
                                }
                            }
                            .Fade-enter,
                            .Fade-leave-to {
                                opacity: 0;
                            }
                            .Fade-enter-to,
                            .Fade-leave {
                                opacity: 1;
                            }
                            .Fade-enter-active,
                            .Fade-leave-active {
                                transition: all 1s;
                            }
                        }
                        .gameShow {
                            transform: translateX(0px);
                            transition: 1.5s;
                        }
                        .gameHide {
                            transform: translateX(-57px);
                            transition: 1.5s;
                        }
                    }
                    .game-btn, .minGame-btn{
                        position: absolute;
                        top:0;right: -100px;
                        img {
                            cursor: pointer;
                            width: 68px;
                            height: 60px;
                            position: absolute;
                            top: 0;right: 0;
                            z-index: 9;
                        }
                        ul,li,div,p,span{margin: 0;padding: 0}
                        ul {
                            width: 60px;
                            background: rgba(0, 0, 0, 0.4);
                            border-radius: 0px 0px 100px 100px;
                            position: absolute;
                            right: 0;top: 30px;
                            z-index: 5;
                            li {
                                color: #ADADB0;
                                font-size: 14px;
                                width: 28px;
                                margin: 0 auto 25px;
                                cursor: pointer;
                                i {
                                    display: block;
                                    width: 24px;
                                    height: 26px;
                                    margin: 0 auto 6px;
                                    background: url("../../assets/images/game/game-icon.png") no-repeat left center;
                                    background-size: 800px 26px;
                                }
                                p {text-align: center}
                            }
                            li:hover p{color: #D5A561;}
                            li:nth-of-type(1) {
                                margin-top: 50px;
                                width: 34px;
                                i {
                                    width: 18px;
                                    height: 12px;
                                    background: url("../../assets/images/game/game-ping.png") no-repeat top center;
                                    background-size: cover;

                                }
                                h6{
                                    color: #6DD400;
                                    font-size: 12px;
                                    font-weight: 200;
                                    text-align: center;
                                    margin: 0;padding: 0;
                                }
                                p {
                                    text-align: center;
                                    margin: 0;padding: 0;
                                }
                            }
                            li:nth-of-type(1):hover p {
                                color: #ADADB0;
                            }
                            li:nth-of-type(2) {
                                i {
                                    background-position: -88px 0px;
                                }

                            }
                            li:nth-of-type(2):hover i {
                                background-position: -128px 0px;
                            }
                            li:nth-of-type(3) {
                                position: relative;
                                i {
                                    background-position: -8px 0px;
                                }
                                div {
                                    width: 58px;
                                    height: 113px;
                                    background-image:url("../../assets/images/game/game-arrow.png");
                                    background-repeat: no-repeat;
                                    background-color: rgba(0,0,0,0.6);
                                    background-position: 4px 51px;
                                    background-size: 12px 12px;
                                    position: absolute;
                                    left: 35px;top: -30px;
                                    section {
                                        width: 34px;
                                        height: 37px;
                                        margin-left: 17px;
                                        line-height: 37px;
                                        text-align: center;
                                        border-bottom: 1px solid #ADADB0;
                                    }
                                    section:last-of-type{
                                        border-bottom: none;
                                    }
                                    section:hover{color: #D5A561}
                                }
                            }
                            li:nth-of-type(3):hover i {
                                background-position: -48px 0px;
                            }
                            li:nth-of-type(4) {
                                position: relative;
                                i:nth-of-type(1) {
                                    background-position: -168px 0px;
                                }
                                i:nth-of-type(2) {
                                    background-position: -288px 0px;
                                }
                                .game-voice {
                                    width: 210px;
                                    height: 49px;
                                    border-radius: 5px;
                                    background: #222222;
                                    position: absolute;
                                    top: 0;right: 45px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding: 0 20px;
                                    /deep/ .el-slider__bar {
                                        background-color: #D5A561;
                                    }
                                    /deep/ .el-slider__button {
                                        border-color: #FFFFFF;
                                    }
                                    /deep/ .el-slider__runway {
                                        background-color: #ADADB0;
                                    }
                                    span {
                                        width: 20px;
                                        height: 49px;
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                        text-align: center;
                                        line-height: 49px;
                                        margin-left: 15px;
                                    }
                                }
                                .arrow-right {
                                    display: block;
                                    width: 0;
                                    height: 16px;
                                    border: 9px solid transparent;
                                    border-left: 9px solid #222222;
                                    position: absolute;
                                    top: 15px;right: 27px;
                                }
                            }
                            li:nth-of-type(4):hover i:nth-of-type(1) {
                                background-position: -208px 0px;
                            }
                            li:nth-of-type(4):hover i:nth-of-type(2) {
                                background-position: -248px 0px;
                            }
                            li:nth-of-type(4) {
                                i {
                                    background-position: -288px 0px;
                                }
                            }
                            li:nth-of-type(5) {
                                i {
                                    width: 26px;
                                    background-position: -327px 0px;
                                }
                            }
                            li:nth-of-type(6) {
                                position: relative;
                                i {
                                    background-position: -529px 0px;
                                }
                            }
                            li:nth-of-type(6):hover i {
                                background-position: -569px 0px;
                            }
                            li:nth-of-type(8) {
                                position: relative;
                                i {
                                    background-position: -449px 0px;
                                }
                                div {
                                    width: 362px;
                                    border-radius: 10px;
                                    background-image:url("../../assets/images/game/game-right.png");
                                    background-repeat: no-repeat;
                                    background-color: rgba(0,0,0,0.6);
                                    background-position: center right;
                                    background-size: 12px 12px;
                                    position: absolute;
                                    left: -375px;top: -12px;
                                    section {
                                        width: 340px;
                                        height: 40px;
                                        margin: 0 auto;
                                        border-bottom: 1px solid rgba(96, 96, 98, 0.5);
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        span:nth-child(1) {
                                            margin-left: 5px;
                                            color: #FFFFFF;
                                            font-size: 14px;
                                        }
                                        span:nth-child(2) {
                                            width: 40px;
                                            color: #D5A561;
                                            font-size: 14px;
                                        }
                                    }
                                    section:nth-child(2) {
                                        span:nth-child(1) {
                                            color: red;
                                        }
                                    }
                                    section:nth-child(3) {
                                        border-bottom: none;
                                    }
                                }
                            }
                            li:nth-of-type(8):hover i {
                                background-position: -489px 0px;
                            }
                            li:nth-of-type(7) {
                                i {
                                    background-position: -608px 0px;
                                }

                            }
                            li:nth-of-type(7):hover i {
                                background-position: -648px 0px;
                            }
                            .archiveSuc {
                                i {
                                    background-position: -688px 0px!important;
                                }
                            }
                            .archiveSuc:hover i{
                                background-position: -688px 0px!important;
                            }
                            li:last-of-type {
                                i {
                                    background-position: -410px 0px;
                                }
                            }
                            li:last-of-type:hover i {
                                background-position: -370px 0px;
                            }
                        }
                        .Fade-enter,
                        .Fade-leave-to {
                            opacity: 0;
                        }
                        .Fade-enter-to,
                        .Fade-leave {
                            opacity: 1;
                        }
                        .Fade-enter-active,
                        .Fade-leave-active {
                            transition: all 1s;
                        }
                    }
                    .minGame-btn {
                        position: absolute;
                        top:-115px;right: 15px;
                    }
                    .game_input {
                        width: 390px;
                        height: 48px;
                        position: absolute;
                        top: 20px;left: 50%;
                        transform: translateX(-50%);
                        background: linear-gradient(to right,#FAD8A1,#E3AF6D);
                        padding: 0 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        input {
                            background: rgba(255,255,255,0.8);
                            width: 314px;
                            height: 40px;
                            border-radius: 5px 0 0 5px;
                            padding-left: 10px;
                        }
                        button {
                            width: 72px;
                            height: 40px;
                            background: linear-gradient(154deg, #FAD8A1 0%, #E3AF6D 100%);
                            border: none;
                            outline: none;
                            border-radius: 0 5px 5px 0;
                        }
                    }
                    .game_title {
                        width: 100%;
                        position: absolute;
                        top: -60px;
                        overflow-x: hidden;
                        h2 {
                            float: left;
                            font-size: 30px;
                            color: #D5A561;
                            margin: 0 2px 18px 0;
                        }
                        .game_areas {
                            float: left;
                            width: 222px;
                            height: 40px;
                            background-image: url("../../assets/images/game/game-point.png");
                            background-repeat: no-repeat;
                            background-position: 5px center;
                            background-size: 8px 8px;
                            font-size: 14px;
                            font-weight: 300;
                            display: flex;
                            align-items: center;
                            p,span{margin: 0;padding: 0;}
                            p {
                                padding-left: 20px;
                                height: 40px;
                                line-height: 40px;
                                color: #D5A561;
                                span {
                                    color: #FAD8A1;
                                }
                            }
                        }
                        .game_timeCount {
                            float: right;
                            // width: 370px;
                            height: 40px;
                            //background: rgba(0, 0, 0, 0.5);
                            //box-shadow: 0px 2px 4px 0px rgba(250, 216, 161, 0.19);
                            //border-radius: 4px;
                            //left: 50%;
                            //transform: translateX(-50%);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            p,span{margin: 0;padding: 0;}
                            p {
                                height: 40px;
                                line-height: 40px;
                                color: #FFFFFF;
                                font-size: 14px;
                                span {
                                    margin-left: 8px;
                                }
                            }
                            p:nth-child(1){
                                span {
                                    color: #FA6400;
                                    font-weight: 300;
                                }
                            }
                            p:nth-child(2){
                                width: 1px;
                                height: 20px;
                                background: rgba(250, 216, 161, 0.3);
                                border-radius: 1px;
                                margin: 0 16px;
                                transform:rotate(30deg);
                            }
                            p:nth-child(3){
                                span {
                                    font-size: 14px;
                                    i {
                                        font-size: 14px;
                                        color: #D5A561;
                                        font-weight: 600;
                                        margin: 0 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .game-footer {
            position: absolute;
            bottom: 0;left: 50%;
            transform: translate(-50%);
            width: 100%;
            text-align: center;
            a {
                color: #B49551;
                font-weight: bolder;
                margin-right: 10px;
            }
            p {
                text-align: center;
            }
        }
        .game-comment, .game-comments{
            position: absolute;
            cursor: pointer;
            top: 10px;right: 100px;
            width: 220px;
            height: 48px;
            background-color: #2F333D;
            background-image: url("../../assets/images/officialWebsite/game/game-grade.png");
            background-repeat: no-repeat;
            background-size: 22px 26px;
            background-position: 32px center;
            border-radius: 24px;
            border: 1px solid #606062;
            font-size: 16px;
            font-weight: 400;
            color: #E6E6E6;
            line-height: 48px;
            padding-left: 90px;
        }
        .game-comments {
            right: 10px;
        }
    }
</style>
